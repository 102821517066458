import React, { Component } from 'react';
import { graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';
import { Layout, SliceZone } from '../components';
import { VideoBlock, HtmlContent, Image } from '../components/common';
import './project.scss';

class Project extends Component {
  render() {
    const {
      data: {
        project,
      },
      location,
    } = this.props;
    const { data } = project;
    const {
      client,
      projectName,
      featuredImage,
      featuredVideo,
      openingDescription,
      openingImages,
      body,
      metaTitle,
      metaDescription,
      openGraphImage,
    } = data;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    const hasVideo = !isEmpty(featuredVideo);
    return (
      <Layout
        location={location}
        seoData={seoData}
      >
        <div className="project-title">
          <h1 className="project-client">{client.text}</h1>
          <span className="project-name">{projectName.text}</span>
        </div>
        {hasVideo ? (
          <VideoBlock
            videoUrl={featuredVideo[0].videoUrl.url}
            videoProvider={featuredVideo[0].videoProvider}
          />
        ) : (
          <div className="featured-image">
            <Image image={featuredImage} />
          </div>
        )}
        <div className="opening-section">
          <div className="cols">
            <div className="col col-left" />
            <div className="col col-right">
              <HtmlContent className="opening-description" content={openingDescription.html} />
              <div className="opening-images">
                { openingImages && openingImages.map((imageData, index) => {
                  const { image } = imageData;
                  return (
                    <div key={index} className="image-wrapper">
                      <Image image={image} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <SliceZone allSlices={body} location={location} />
      </Layout>
    );
  }
}

export default Project;

export const postQuery = graphql`
query ProjectBySlug($uid: String!) {
  project: prismicProject(uid: { eq: $uid }) {
    uid
    data {
      metaTitle: meta_title {
        html
        text
      },
      metaDescription: meta_description {
        html
        text
      },
      openGraphImage: open_graph_image {
        alt
        copyright
        url
      }
      client {
        text
      }
      projectName: project_name {
        text
      }
     featuredImage: featured_image {
       fluid(maxWidth: 1000) {
         ...GatsbyPrismicImageFluid
       }
       url
       alt
      }
      featuredVideo: featured_video {
        videoProvider: video_provider
        videoUrl: video_url {
          url
        }
      }
      openingDescription: opening_description {
        html
      }
      openingImages: opening_images {
        image {
          fluid(maxWidth: 1000) {
            ...GatsbyPrismicImageFluid
          }
          url
          alt
        }
      }
      body {
        ... on PrismicProjectBodyProjectSection {
          id
          primary {
            sectionTitle: section_title {
              text
            }
            sectionDescription: section_description {
              html
            }
          }
          items {
            image {
              fluid(maxWidth: 1000) {
                ...GatsbyPrismicImageFluid
              }
              url
              alt
            }
          }
          sliceType: slice_type
        }
      }
    }
  }
}
`;
